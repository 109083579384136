import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
          
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/cookie-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/cookie-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-cookies/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politicia-de-cookies/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/cookie-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/cookie-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h1>COOKIE POLICY</h1>
                <p> </p>
                <p>
                This Cookie Policy is designed to tell you about our practices regarding the collection of information through cookies and other tracking technologies (like gifs, web beacons, etc.).
                </p>
                <p>
                The functionalities for which we use these technologies may include the following:
                </p>
                <ul>
                  <li>Assisting you in navigation,</li>
                  <li>Assisting in registering for our events, login, and your ability to provide feedback,</li>
                  <li>Analyzing the use of our products, services or applications,</li>
                  <li>Assisting with our promotional and marketing efforts (including behavioral advertising),</li>
                  <li>Offering content of third parties (such as social media content).</li>
                </ul>
                <p>
                Below is a detailed list of the cookies we use, with a description. We classify cookies in the following categories:
                </p>
                <ul>
                  <li>Strictly Necessary Cookies</li>
                  <li>Performance Cookies</li>
                  <li>Functional Cookies</li>
                  <li>Targeting Cookies</li>
                </ul>
                <p> Except where allowed by applicable law, we place cookies after having received your consent through the cookie banner or preference center. You can change your cookie settings per cookie category (except for

                    strictly necessary cookies that are required for the proper functioning of the site) at any time by clicking on the “cookie settings” button below:</p>
                
                <p>
                <div>
                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Policy</button>
                </div>
                <br/>
              <br/>
              </p>
              <p>
                <div id="ot-sdk-cookie-policy"></div>
                &nbsp;
              </p>
                <h2>LINKS TO OTHER WEB SITES</h2>
                <p>
                  This site may contain links or references to other websites. 
                  Please be aware that we do not control the cookies / tracking
                  technologies of other websites and this Cookie Policy does not
                  apply to those websites.
                </p>
                <p> </p>
                <h2>HOW TO CONTACT US</h2>
                <p>
                If you have any questions, comments, or concerns about this Cookie Policy or the information practices of this site, please use the contact information included in our Privacy Policy.
                </p>                              
                <p> </p>
                <h2>CHANGES TO THIS COOKIE POLICY</h2>
                <p>
                  If this Cookie Policy changes, the revised policy will be
                  posted on this site. This Cookie Policy was last updated on 2
                  February 2014.
                </p>
                <p> </p>                             
                
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
